import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/8MBeU83Pp8U">
    <SEO title="An Overview of the Book - Hebrews" />
  </Layout>
)

export default SermonPost
